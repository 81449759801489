let baseDomain =
    process.env.NODE_ENV === 'production'
        ? document.location.origin
        : import.meta.env.VITE_BACKEND_BASE_URL;

if (import.meta.env.VITE_BUILD_LOGIN) {
    baseDomain = import.meta.env.VITE_LOGIN_STAGING
        ? import.meta.env.VITE_LOGIN_VTC_STAGING
        : import.meta.env.VITE_LOGIN_VTC_PRODUCTION;
}

export const BACKEND_BASE = baseDomain;
// export const API_BASE_PATH = import.meta.env.VITE_API_BASE_PATH ;
export const API_BASE_PATH = 'json-api/v1';
export const API_BASE = [baseDomain, API_BASE_PATH].join('/');
export const API_ENDPOINT = lang => [baseDomain, API_BASE_PATH, lang].join('/');
export const API_TRACK = "/api/craft-track/track";
export const API_TRACKING_DATA = "/api/craft-track/api/data";

export const isDev = import.meta.env.DEV;

export default {
    API_BASE,
    BACKEND_BASE,
    API_TRACK,
    isDev,
};
