import { API_ENDPOINT, BACKEND_BASE } from '@/config';
import axios from 'axios';
import { defineStore } from 'pinia';
import { useQnaTopicsStore } from '.';

export const useQnaMyQuestionsStore = defineStore('qnaMyQuestions', {
    state: () => ({
        loading: false,

        questions: [],
        topics: [],

        input: '',
        selectedTopic: '',
    }),

    getters: {
        getInput(state) {
            return state.input.toLowerCase();
        },
        getTopics(state) {
            const qnaTopicsStore = useQnaTopicsStore();
            return qnaTopicsStore.getTopics;
        },
        getQuestions(state) {
            let questions = state.questions;
            if (state.selectedTopic) {
                questions = state.questions
                    .filter(({ topic }) => topic.id === state.selectedTopic);
            }
            if (state.input.length > 0) {
                questions = questions
                    .filter(({ question }) => question.toLowerCase().includes(this.getInput))
            }

            questions = questions.map((q) => {
                return {
                    ...q,
                    author: null
                }
            });

            return questions;
        },

        getUpdatedQuestions(state) {
            return this.getQuestions.filter(({ hasUnreadChanges }) => hasUnreadChanges);
        },
        getNoUpdatedQuestions(state) {
            return this.getQuestions.filter(({ hasUnreadChanges }) => !hasUnreadChanges);
        },

        hasAnyUnreadChanges(state) {
            return this.getQuestions.filter(({ hasUnreadChanges }) => hasUnreadChanges).length > 0;
        }
    },
    actions: {
        async loadQuestions({ lang = null, queryParams = {} } = {}) {
            // if (this.loaded) { return; }
            this.loading = true;

            const response = await axios.get(
                `${BACKEND_BASE}/api/qna/questions/me`,
                {
                    withCredentials: true,
                    params: queryParams,
                    validateStatus: status => status < 400,
                }
            );

            this.questions = response.data;
            this.loading = false;
            // this.loaded = true;
        },

        async loadTopics({ lang = null, queryParams = {} } = {}) {

            const response = await axios.get(
                `${BACKEND_BASE}/api/qna/topics`,
                {
                    withCredentials: true,
                    params: queryParams,
                    validateStatus: status => status < 400,
                }
            );

            this.topics = response.data;
        },

        async vote(id) {

            try {

                const response = await axios.get(
                    `${BACKEND_BASE}/api/qna/votes/vote/${id}`,
                    {
                        // params: queryParams,
                        withCredentials: true,
                        validateStatus: status => status < 400,
                    }
                );

                return true;

            } catch (err) {

                return false;

            }
        },

        async markAsRead(id) {

            try {

                const response = await axios.get(
                    `${BACKEND_BASE}/api/qna/questions/${id}/read`,
                    {
                        // params: queryParams,
                        withCredentials: true,
                        validateStatus: status => status < 400,
                    }
                );

                return true;

            } catch (err) {

                return false;

            }
        },

    },
});
