<script setup>
import { useAltaresCoursesStore, useFloatingButtonsStore } from '@/store';
import { Icon } from '@bam/icon';
import { PopupButton } from '@bam/popup-button';
import { RichText } from '@bam/rich-text';

const props = defineProps({
    ...PopupButton.props
});
const floatingButtonsStore = useFloatingButtonsStore();
const altaresCoursesStore = useAltaresCoursesStore();
</script>

<template>
    <PopupButton
        v-bind="$props"
        :disabled="!altaresCoursesStore?.data.length"
        :headline="floatingButtonsStore?.training?.headline"
        class="training-fab"
    >

        <template #icon>
            <Icon name="study" />
        </template>

        <template #default>
            <RichText>
                <ul class="training-fab__list">
                    <li v-for="(item, index) in altaresCoursesStore.data" :key="item.id">
                        <a :href="item.url" target="_blank">{{item.name}}</a>
                    </li>
                </ul>
            </RichText>
        </template>

        <template #link>
            <a v-bind="floatingButtonsStore?.training?.link">
                {{floatingButtonsStore?.training?.link?.label}}
            </a>
        </template>

    </PopupButton>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.training-fab {

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {

            border-bottom: 1px solid var(--bam-color-light);
            display: block;
            padding: 8px 0;
            text-decoration: none;

            a {
                text-decoration: none;
            }
        }
    }
}
</style>
