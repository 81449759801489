import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useContentStore = defineStore("content", {
    state: () => ({
        id:  null,
        meta: {},
        slug: null,
        title: null,
        type: null,
        richText: null,
        content: [],
        download: null,
        video: null,
        embeddedVideos: null,
        externalLink: null,
        image: null,
        recommendedContentEnabled: null,
        recommendedContent: [],
    }),

    getters: {

        hasCompletionTracker(state) {
            return state.content.some(({ handle }) => handle === 'completion-tracker')
        },

        getType(state) {
            switch (state.type) {
                case 'video': return 'EntryTypeVideo';
                case 'download': return 'EntryTypeDownload';
                case 'softContent': return 'EntryTypeSoftContent';
                case 'defaultContent': return 'EntryTypeContent';
                case 'externalContent': return 'EntryTypeExternalContent';
                // case 'externalLink': return null;
                default: return null;
            }
        },

        getProps(state) {
            switch (state.type) {
                case 'video': return {
                    meta: state.meta,
                    slug: state.slug,
                    title: state.title,
                    type: state.type,

                    richText: state.richText,
                    video: state.video,
                    recommendedContent: state.recommendedContent,
                    recommendedContentEnabled: state.recommendedContentEnabled,
                };
                case 'externalContent': return {
                    meta: state.meta,
                    slug: state.slug,
                    title: state.title,
                    type: state.type,

                    richText: state.richText,
                    embeddedVideos: state.embeddedVideos,
                };
                case 'download': return {
                    meta: state.meta,
                    slug: state.slug,
                    title: state.title,
                    type: state.type,

                    richText: state.richText,
                    download: state.download,
                    image: state.image,
                };
                case 'softContent': return {
                    meta: state.meta,
                    slug: state.slug,
                    title: state.title,
                    type: state.type,

                    content: state.content,
                };
                case 'defaultContent': return {
                    meta: state.meta,
                    slug: state.slug,
                    title: state.title,
                    type: state.type,

                    content: state.content,
                    recommendedContent: state.recommendedContent,
                    recommendedContentEnabled: state.recommendedContentEnabled,
                };

                default: return null;
            }

        }

    },

    actions: {
        async load({ slug, lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/contents/slug/${slug}`,
                {
                    params: queryParams,
                    validateStatus: (status) => status < 400
                }
            );

            Object.assign(this.$state, response.data);

            return response;

        }
    },

    craft: {
        sectionType: 'channel',
    },
});

