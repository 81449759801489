<script setup>
import { computed } from 'vue';
import { useFloatingButtonsStore } from '@/store';
import { Icon } from '@bam/icon';
import { PopupButton } from '@bam/popup-button';
import { RichText } from '@bam/rich-text';

const props = defineProps({
    ...PopupButton.props
});

const floatingButtonsStore = useFloatingButtonsStore();

const linkLabel = computed(() => {
    return floatingButtonsStore?.exploreMode
        ? floatingButtonsStore.explore?.activeLabel
        : floatingButtonsStore.explore?.inactiveLabel;
});
</script>

<template>
    <PopupButton
        v-bind="$props"
        :headline="floatingButtonsStore?.explore?.headline"
    >

        <template #icon>
            <Icon name="explore" />
        </template>

        <template #default>
            <RichText :text="floatingButtonsStore?.explore?.text" />
        </template>

        <template #link>
            <span @click="floatingButtonsStore.toggleExploreMode">
                {{ linkLabel }}
            </span>
        </template>

    </PopupButton>
</template>

<style lang="scss">
@use "@bam/scss" as *;

</style>
