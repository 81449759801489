<script setup>
import { Icon } from '@bam/icon';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const href = computed(() => window.history?.state?.back ?? '/');

const goBack = () => {
    const hasBack = !!window.history?.state?.back;

    if (hasBack) {
        router.back();
    } else {
        router.push({ path: '/' });
    }
}
</script>

<template>
    <a
        class="back-link neutral-link"
        :href="href"
        @click.prevent="goBack"
    >
        <Icon name="lined-arrow-left" />
    </a>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.back-link {

    @include v((
        link-size: 35px
    ));

    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-start: content-start;
    color: v(color-white);
    margin: v(dimension-little);
    background-color: v(color-secondary);
    border-radius: 50%;
    width: v(link-size);
    height: v(link-size);
    transition: background-color v(easing-default) v(duration-default);

    @include hover {
        background-color: v(color-primary);
    }
}
</style>
