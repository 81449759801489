<script setup>
import { useFloatingButtonsStore, useQnaMyQuestionsStore } from '@/store';
import { Icon } from '@bam/icon';
import { PopupButton } from '@bam/popup-button';
import { ref } from 'vue';
import { RichText } from '@bam/rich-text';

const props = defineProps({
    ...PopupButton.props,
});

const qnaModalOpen = ref(false);
const floatingButtonsStore = useFloatingButtonsStore();
const qnaMyQuestionsStore = useQnaMyQuestionsStore();
</script>

<template>
    <PopupButton
        v-bind="$props"
        :badge="qnaMyQuestionsStore.hasAnyUnreadChanges"
        :headline="floatingButtonsStore?.chat?.headline"
    >
        <template #icon>
            <Icon name="chat" />
        </template>

        <template #default>
            <RichText :text="floatingButtonsStore?.chat?.text" />
        </template>

        <template #link>
            <a @click.prevent="qnaModalOpen = true" href="#">{{floatingButtonsStore?.chat?.linkLabel}}</a>
        </template>

    </PopupButton>
    <Teleport to="body">
        <QnaModal
            :contentHeightFixed="false"
            v-model:open="qnaModalOpen"
        />
    </Teleport>
</template>

<style lang="scss">
@use "@bam/scss" as *;

</style>
