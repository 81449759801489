<script setup>
import { useQnaMyQuestionsStore, useQnaGlobalsStore } from '@/store';
import { RichText } from '@bam/rich-text';

const { lang } = defineProps({
    lang: {
        type: String,
        default: 'de'
    },
})

const qnaGlobalsStore = useQnaGlobalsStore();
const qnaStore = useQnaMyQuestionsStore();
qnaStore.loadQuestions({ lang });
qnaStore.loadTopics({ lang });
</script>

<template>
    <div class="qna-my-questions">

        <template v-if="qnaStore.getUpdatedQuestions.length > 0 || qnaStore.getNoUpdatedQuestions.length > 0">
            <QuestionList
                v-if="qnaStore.getUpdatedQuestions.length > 0"
                :headline="qnaGlobalsStore.qnaMyQuestionsUpdatesLabel"
                :labels="{
                    question: {
                        votes: qnaGlobalsStore.qnaQuestionVotesLabel,
                        answers: qnaGlobalsStore.qnaQuestionAnswersLabel,
                    },
                    answer: {
                        statusMessage: qnaGlobalsStore.qnaAnswerStatusMessageLabel,
                        answer: qnaGlobalsStore.qnaAnswerAnswerLabel,
                    }
                }"
                :questions="qnaStore.getUpdatedQuestions"
                :topics="qnaStore.getTopics"
                :loading="qnaStore.loading"

                @question-opened="(id) => qnaStore.markAsRead(id)"
            />

            <QuestionList
                :headline="qnaGlobalsStore.qnaMyQuestionsMyQuestionsLabel"
                :labels="{
                    question: {
                        votes: qnaGlobalsStore.qnaQuestionVotesLabel,
                        answers: qnaGlobalsStore.qnaQuestionAnswersLabel,
                    },
                    answer: {
                        statusMessage: qnaGlobalsStore.qnaAnswerStatusMessageLabel,
                        answer: qnaGlobalsStore.qnaAnswerAnswerLabel,
                    }
                }"
                :questions="qnaStore.getNoUpdatedQuestions"
                :topics="qnaStore.getTopics"
                :loading="qnaStore.loading"
            />
        </template>
        <template v-else>
            <RichText
                :text="qnaGlobalsStore.qnaMyQuestionsNoQuestionsHint"
            />
        </template>
    </div>
</template>

<style lang="scss">
@use "@bam/scss" as *;
.qna-my-questions {
    display: flex;
    flex-flow: column;
    gap: v(size-42);
}
</style>
