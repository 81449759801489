import { API_ENDPOINT, BACKEND_BASE } from '@/config';
import axios from 'axios';
import { defineStore } from 'pinia';

export const useQnaTopicsStore = defineStore('qnaTopics', {
    state: () => ({
        loaded: false,
        topics: [],
    }),
    getters: {
        getTopics(state) {
            return state.topics
                .map(({ id, name, description }) => ({ id, label: name, value: id, description }))
        },
    },
    actions: {
        async loadTopics({ lang = null, queryParams = {} } = {}) {
            this.loaded = false;

            const response = await axios.get(
                `${BACKEND_BASE}/api/qna/topics`,
                {
                    withCredentials: true,
                    params: queryParams,
                    validateStatus: status => status < 400,
                }
            );

            this.topics = response.data;

            this.loaded = true;
        },
    },
});
