import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useUiTextsStore = defineStore("uiTexts", {
    state: () => ({
        loaded: false,

        uiTexts: {},
        meta: {}
    }),
    actions: {
        async load({ lang, queryParams = {} }) {

            const response = await axios.get(`${API_ENDPOINT(lang)}/uiTexts`, {
                params: queryParams,
                transformResponse: [
                    function (data) {
                        try {
                            const jsonData = JSON.parse(data);
                            if (!jsonData?.data) { return jsonData; }

                            jsonData.data = jsonData.data.reduce((obj, item) => ({ ...obj, ...item }), {});

                            return jsonData;

                        } catch(err) {
                            return data;
                        }
                    }
                ],
            });

            const { meta = null, data = null } = response.data;

            this.uiTexts = data;
            this.meta = meta;

            return response;
        }
    }
});
