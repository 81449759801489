<script setup>
import { computed } from 'vue';
import { RichText } from '@bam/rich-text';
import Author from './Author.vue';

const props = defineProps({
    id: [String, Number],
    label: String,
    answer: String,
    votes: Number,
    isVerified: Boolean,
    verifiedBy: Object,
    status: Object,
    topic: Object,
    author: Object,
    dateCreated: [String, Date],
    dateUpdated: [String, Date],
    dateLastRead: [String, Date],
    dateLastRelevantChange: [String, Date],
    labels: Object,
})

const dateCreated = computed(() => new Date(props.dateCreated));
const dateUpdated = computed(() => new Date(props.dateUpdated));
</script>

<template>
    <section class="answer">

        <!-- <i
            v-show="isVerified"
            class="answer__badge"
        /> -->

        <header class="answer__header" v-if="label">
            {{label}}
        </header>

        <main class="answer__main">
            <RichText
                class="answer__text"
                :text="answer"
            />
        </main>

        <footer class="answer__footer">

            <Author
                class="answer__author"
                :date="dateCreated"
                :author="author.username"
                :locale="locale"
            />

        </footer>
    </section>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.answer {
    @include v((
        size-badge: v(size-20),
        color-badge: v(color-secondary),
        color-header: v(color-primary),
        font-header: 700 21px/1.4762 v(font-family-default-1),
    ));

    display: grid;
    grid-template-columns: [badge-start] v(size-badge) [badge-end content-start] 1fr [content-end];
    gap: v(size-10);

    position: relative;
    width: 100%;
    gap: v(size-10);
    padding: v(size-26) v(size-26) v(size-42) v(size-16);
    background-color: hsla(v(color-primary-hsl), 0.1);

    &__badge {
        // grid-column: badge;
        // width: v(size-badge);
        // height: v(size-badge);
        // background: v(color-badge);
        // border-radius: 50%;
        // box-shadow: inset 1px 2px 3px rgba(255, 255, 255, 0.35);
        // margin-top: 3.5px !important;
    }

    &__header {
        grid-column: content;
        margin: 0;
        font: v(font-header);
        color: v(color-header);
    }

    &__main {
        grid-column: content;
        display: flex;
        flex-flow: column;
        width: 100%;
    }

    &__footer {
        grid-column: content;
        display: flex;
        flex-flow: row;
        width: 100%;
        justify-content: flex-end;
        align-items: flex-start;
    }

    &__text.rich-text {

        & > * {
            max-width: none;
        }
    }

    &__author {
        align-self: flex-end;
    }

}
</style>
