import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useCourseStore = defineStore("course", {
    state: () => ({
        loaded : true,

        id: null,
        title: null,
        meta: {},
        content: []
    }),

    getters: {
        hasCompletionTracker(state) {
            return state.content.some(({ handle }) => handle === 'completion-tracker')
        },
    },

    actions: {
        async load({ slug, lang, queryParams = {} }) {
            this.loaded = false;

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/courses/slug/${slug}`,
                {
                    params: queryParams,
                    validateStatus: (status) => status < 400
                }
            );

            Object.assign(this.$state, response.data);

            this.loaded = true;

            return response;
        }
    }
});

