<script setup>
import { InfiniteLoadingBar } from '@bam/infinite-loading-bar';

const props = defineProps({
    label: String,
    description: String,
    value: String,
    type: {
        type: String,
        default: 'text'
    },
    placeholder: String,
    required: Boolean,
    disabled: Boolean,
    error: Boolean,
    loading: Boolean,
});
const emit = defineEmits([ 'update:value' ]);
const updateValue = (event) => {
    emit('update:value', event.target.value);
}
</script>

<template>
    <div
        class="text-input"
        :class="{
            'text-input--disabled': disabled,
            'text-input--error': error
        }"
    >
        <label class="text-input__text">

            <span class="text-input__label">
                {{ label }}<span v-if="required" class="text-input__label--required">*</span>
            </span>

            <div
                v-if="description"
                v-html="description"
                class="text-input__description"
            />

            <input
                autocomplete="off"
                :type="type"
                :placeholder="placeholder"
                :required="required"
                :disabled="disabled"
                :value="value"
                @input="updateValue"
                class="text-input__field text-input__field--text" />


            <InfiniteLoadingBar v-if="loading" class="text-input__loader" />
        </label>
    </div>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.text-input {
    display: flex;
    flex-flow: column;

    @include v((
        font-input-label: ( default: 400 16px/1.3 #{v(font-family-default-1)} ),
        font-input-placeholder: ( default: 400 14px/1.357 #{v(font-family-default-2)} ),
    ));


    &__text {
        grid-column: text-start / text-end;
        grid-row: text-start / text-end;
        display: flex;
        flex-flow: column;
        gap: v(size-2);
        position: relative;

        // font: v(font-heading-4);
        font: v(font-input-label);
        color: v(color-primary);

        & + .text-input__field {
            margin-top: v(size-4);
        }
    }

    &__label--required {
        color: v(color-danger);
    }

    &__description {
        font: v(font-copy--small);
    }

    &__field {
        font: v(font-copy--small);

        &::placeholder {
            opacity: 1;
            font: v(font-copy--small);
            color: v(color-light);
        }

        &:focus {
            outline: 0;
            border-color: v(color-primary);
        }

        &--text {
            display: flex;
            background-color: v(color-white);
            border: 1px solid hsla(v(color-black-hsl), 0.2);
            color: v(color-dark);
            box-shadow: none;
            padding: v(size-16) v(size-16);
            resize: none;
            outline: none;
            scrollbar-width: thin;
            scrollbar-color: v(color-secondary) transparent;
            transition: border-color v(easing-default) v(duration-default);
        }

    }

    &__loader {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &--disabled {
        pointer-events: none;

        .text-input__label--required,
        .text-input__text {
            color: v(color-light);
        }

        .text-input__field {
            background-color: v(color-white);
            border-color: v(color-light);
            color: v(color-light);

            &::placeholder {
                color: v(color-light);
            }
        }

    }

    &--error {
        .text-input__field {
            border-color: v(color-danger);
            color: v(color-danger);

            &::placeholder {
                color: hsla(v(color-danger-hsl), .8);
            }
        }
    }
}
</style>
