<script setup>
import { computed } from 'vue';

const props = defineProps({
    date: [String, Date],
    author: String,
    dateTimeTemplate: {
        type: String,
        default: `{date}`,
    },
    authorTemplate: {
        type: String,
        default: `{author}`,
    },
    locale: {
        type: String,
        default: 'de'
    }
});

const isDate = date => date instanceof Date;
const reallyADate = computed(() => isDate(props.date) ? props.date : new Date(props.date))
const date = computed(() => reallyADate.value.toLocaleDateString(props.locale));
const time = computed(() => reallyADate.value.toLocaleTimeString(props.locale));
const dateTime = computed(() => props.dateTimeTemplate
    ?.replaceAll('{date}', date.value)
    .replaceAll('{time}', time.value)
);

const author = computed(() => props.authorTemplate?.replaceAll('{author}', props.author));
const authorString = computed(() => {
    let label = dateTime?.value;
    if (dateTime.value && props.author) { label += ', '; }
    if (props.author) { label += author.value; }

    return label;
})
</script>

<template>
    <div class="author">{{ authorString }}</div>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.author {
    @include v((
        font-author: (
            default: 700 12px/1 v(font-family-default-1)
        )
    ));

    display: inline-flex;
    flex-flow: row wrap;
    gap: 0.5ch;
    text-align: right;
    font: v(font-author);
    text-transform: uppercase;
    justify-content: flex-end;
}

</style>
