import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";
import { useUserStore } from "@/store";

export const useFloatingButtonsStore = defineStore("floatingButtons", {
    state: () => ({
        loaded: false,
        training: null,
        chat: null,
        explore: null,
        meta: null,
        // exploreMode: false,
    }),
    getters: {
        exploreMode(state) {
            const userStore = useUserStore();

            return userStore.preferences.exploreMode;
        }
    },
    actions: {
        async load({ lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/globals/floating-buttons`,
                {
                    params: queryParams
                }
            );

            Object.assign(this.$state, response.data);

            return response;
        },

        toggleExploreMode() {
            const userStore = useUserStore();
            userStore.toggleExploreMode();
            // this.exploreMode = !this.exploreMode;
        },
    }
});


