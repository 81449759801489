<script setup>
import { computed } from 'vue';
import StatDisplay from './StatDisplay.vue';
import Author from './Author.vue';

const props = defineProps({
    subject: String,
    body: String,
    topic: String,
    dateCreated: [String, Date],
    dateUpdated: [String, Date],
    author: String,
    status: Object,
    labels: {
        type: Object,
        default: () => ({
            dateTimeTemplate: `{date} at {time}`,
            authorTemplate: `by {author}`
        })
    },
    locale: {
        type: String,
        default: 'de'
    }
});

const statusModifier = computed(() => ({
    [`question-header__status--${props.status?.name.toLowerCase()}`]: !!props.status?.name
}))
</script>

<template>
    <div class="question-header">

        <div class="question-header__summary">

            <div
                v-if="body"
                v-html="body"
                class="question-header__body"
            />

            <div class="question-header__footer">

                <span class="topic">{{ topic }}</span>

                <div v-if="status?.name" :class="['question-header__status', statusModifier]">
                    {{ $g(`qna-status-${status.name.toLowerCase()}`) }}
                </div>

                <Author
                    class="question-header__author"
                    :date="dateCreated"
                    :author="author"
                    :locale="locale"
                />

            </div>

        </div>
    </div>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.question-header {
    @include v((
        font-status: bold 12px/1 v(font-family-default-1),
    ));
    display: flex;
    flex-flow: row nowrap;
    gap: v(size-10);
    position: relative;
    padding: v(size-26) v(size-26) v(size-42) 46px;


    &__summary {
        display: flex;
        width: 100%;
        flex-flow: column nowrap;
        gap: v(size-10);
    }

    &__subject {
        font: v(font-heading-3);
    }

    &__body {
        font: v(font-copy);
    }

    &__footer {
        align-self: stretch;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: v(size-10);

        .author {
            flex: 1 1 auto;
        }
    }

    &__status {
        font: v(font-status);
        text-transform: uppercase;
        color: v(color-primary);

        &--accepted {
            color: v(color-success);
        }
        &--rejected {
            color: v(color-danger);
        }
        &--submitted {
            color: v(color-primary);
        }
    }


    // &__author {
    //     align-self: end;
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    // }

    .topic {
        display: inline-block;
        background-color: v(color-secondary);
        color: v(color-white);
        padding: v(size-4);
        font: v(font-status);
        text-transform: uppercase;
        font-weight: normal;
    }
}
</style>
