<script setup>
import { Icon } from '@bam/icon';
import { reactivePick } from '@vueuse/core';
import AppLink from './AppLink.vue';

const props = defineProps({
    ...AppLink.props,
    icon: { type: String },
    label: { type: String },
})

const appLinkProps = reactivePick(props, ...Object.keys(AppLink.props));
</script>

<template>
    <AppLink class="sidebar-link" v-bind="appLinkProps">
        <Icon class="sidebar-link__icon" :name="icon" v-if="icon" />
        <span class="sidebar-link__label">
            <slot v-bind="{ label }">{{label}}</slot>
        </span>
    </AppLink>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.sidebar-link {

    @include hover {
        color: v(color-secondary);
    }

    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: v(size-6);
    transition: color v(easing-default) v(duration-default);

    &__icon {
        flex: 0 0 20px;
    }

    &__label {
        font: v(font-copy);
        font-weight: bold;
        color: currentColor;
        text-decoration: none;
    }
}
</style>
