import { defineStore } from "pinia";

export const useLayoutBackgroundStore = defineStore("layoutBackground", {
    state: () => ({
        backgroundImage: {},
    }),
    actions: {
        setBackgroundImage(bgImage) {
            this.backgroundImage = bgImage;
        }
    }
});


