import {
    useAltaresCoursesStore,
    useFloatingButtonsStore,
    useNavigationStore,
    useQnaGlobalsStore,
    useSearchStore,
    useUiTextsStore,
    useUserStore,
    useTrackingStore,
} from "@/store";

import set from "set-value";

function trackPageVisit(id) {
    const trackingStore = useTrackingStore();
    trackingStore.entryId = id;
    trackingStore.trackPageVisit({ id });
}

export async function guardSingleType(store, to, from, track = true) {
    try {
        const response = await store.load({ ...to.params, queryParams: to.query });

        if (track) {
            trackPageVisit(response.data.id);
        }

        return true;
    } catch ({ response, message }) {

        if (response.status === 403) {
            // console.log("loading /", response.status)
            if (window.location.pathname !== '/') {
                window.location = '/'
            }
        }

        const uri = to.path.split('/').slice(2);
        return { name: 'page-404-lang', params: { lang: to.params.lang, uri, err: message } };
    }
}

export async function guardChannelType(store, to, from, track = true) {
    try {
        const response = await store.load({ ...to.params, queryParams: to.query });

        if (track) {
            trackPageVisit(response.data.id);
        }

        return true;
    } catch ({ response, message }) {

        if (response.status === 403) {
            // console.log("loading /", response.status)
            if (window.location !== '/')
            window.location = '/'
        }

        const uri = to.path.split('/').slice(2);
        return { name: 'page-404-lang', params: { lang: to.params.lang, uri, err: message } };
    }
}

export async function guardStructureType(store, to, from, track = true) {
    set(to,'params.uri', [].concat(to.params.uri).join("/"));
    try {
        const response = await store.load({ ...to.params, queryParams: to.query });

        if (track) {
            trackPageVisit(response.data.id);
        }

        return true;

    } catch ({ response, message }) {

        if (response.status === 403) {
            // console.log("loading /", response.status)
            if (window.location !== '/')
            window.location = '/'
        }

        const uri = to.path.split('/').slice(2);
        return { name: 'page-404-lang', params: { lang: to.params.lang, uri, err: message } };
    }
}

export async function guardGlobalType(useStore, to, from) {
    const store = useStore();

    if (store.loaded) { return; }

    try {
        await store.load({ ...to.params, queryParams: to.query });
        store.loaded = true;

        return true;
    } catch (err) {
        // TODO: Define error behavior
        return true;
    }
}

export async function guardGlobalStores(to, from) {

    await Promise.allSettled([
        guardAltaresCoursesApi(to, from),
        guardFloatingButtonsStore(to, from),
        guardQnaGlobalsStore(to, from),
        guardNavigationStore(to, from),
        guardSearchStore(to, from),
        guardUiTextsStore(to, from),
        guardUserStore(to, from),
    ]);

}

export async function guardUiTextsStore(to, from) {
    return await guardGlobalType(useUiTextsStore, to, from);
}

export async function guardAltaresCoursesApi(to, from) {
    return await guardGlobalType(useAltaresCoursesStore, to, from);
}

export async function guardFloatingButtonsStore(to, from) {
    return await guardGlobalType(useFloatingButtonsStore, to, from);
}

export async function guardUserStore(to, from) {
    return await guardGlobalType(useUserStore, to, from);
}

export async function guardSearchStore(to, from) {
    return await guardGlobalType(useSearchStore, to, from);
}

export async function guardNavigationStore(to, from) {
    return await guardGlobalType(useNavigationStore, to, from);
}

export async function guardQnaGlobalsStore(to, from) {
    return await guardGlobalType(useQnaGlobalsStore, to, from);
}
