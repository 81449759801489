import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useOverlayContentStore = defineStore("overlayContent", {
    state: () => ({
        id: null,
        loading: false,
        meta: {},
        slug: null,
        title: null,
        type: null,
        content: [],
    }),
    getters: {
    },
    actions: {
        async load({ slug: slugSegment, lang = 'de', queryParams = {} }) {

            this.loading = true;

            try {

                const response = await axios.get(
                    `${API_ENDPOINT(lang)}/contents/slug/${slugSegment}`,
                    {
                        params: queryParams,
                        validateStatus: (status) => status < 400
                    }
                );

                Object.assign(this.$state, response.data);

                return response;

            } finally {
                this.loading = false;
            }
        }
    }
});


