import { API_ENDPOINT, BACKEND_BASE } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";
import { default as get } from "get-value";
import { default as set } from "set-value";

export const useSearchStore = defineStore("search", {
    state: () => ({
        loaded: false,

        loading: false,
        hasSearchError: false,

        input: null,
        labels: {},

        meta: {},
        data: [],

        questions: [],

        isSidebarOpen: false,
    }),
    getters: {
        noResultsMessage(state) {
            return state.labels?.noResults?.replaceAll('{query}', state.meta?.query)
        },

        hasQuestions(state) {
            return state.questions.length > 0;
        },

        hasSearchResults(state) {
            return state.data.length > 0;
        },

        hasFilteredCards(state) {
            return this.cards.length > 0;
        },

        prepareCards(state) {
            return cards => cards.map(card => deepReplace(card, 'sortCriteria.dateUpdated', value => new Date(value)));
        },

        filterCards(state) {
            return (cards) => {

                let filteredCards = cards;

                this?.filterProps?.filterGroups.forEach(({ label, options }) => {

                    const activeOptionIds = options.filter(({ active }) => active).map(({ id }) => id);

                    if (activeOptionIds.length > 0) {
                        filteredCards = filteredCards
                            .filter(({ categories }) => categories.some(cId => activeOptionIds.includes(cId)));

                    }
                });

                return filteredCards
            }
        },

        sortCards(state) {
            return (cards) => cards
                .slice()
                .sort(sortBy(this.getSort))
        },

        cards(state) {
            return this.sortCards(this.filterCards(this.prepareCards(state.data)));
        },

        filterProps(state) {
            return state?.meta?.filterProps;
        },

        getSort(state) {
            return this.filterProps
                ?.sortOptions
                ?.find(({ active }) => active)
                ?.property;
        },

        activeFilters(state) {
            return this.filterProps?.filterGroups
                ?.flatMap(group => group.options)
                ?.filter(({ active }) => active)
        },

        activeFilterCount(state) {
            return this?.activeFilters?.length;
        }
    },
    actions: {
        async reset() {

            this.$patch({
                hasSearchError: false,
                input: null,
                meta: {},
                data: []
            })

        },
        async search({ lang, queryParams = {} } = {}) {

            this.loading = true;
            this.hasSearchError = false;

            try {

                await Promise.all([
                    this.searchContents({ lang, queryParams }),
                    this.searchQuestions({ lang, queryParams })
                ])

            } catch(err) {
                console.log("action search",err);
                this.hasSearchError = true;
            } finally {
                this.loading = false;
            }

        },

        async searchContents({ lang, queryParams = {} } = {}) {

            const response = await axios
                .post(
                    `${API_ENDPOINT(lang)}/search` ,
                    { query: this.input },
                    {
                        withCredentials: true,
                        params: queryParams,
                        validateStatus: status => status < 400,
                    }
                )

            Object.assign(this.$state, response.data);

            return response;

        },

        async searchQuestions({ lang, queryParams = {} } = {}) {

            // const response = await axios.post(
            //     `${BACKEND_BASE}/api/qna/questions/search`,
            //     { query: this.input },
            //     {
            //         withCredentials: true,
            //         params: queryParams,
            //         validateStatus: status => status < 400,
            //     }
            // );

            // TEMP
            const response = await axios.get(
                `${BACKEND_BASE}/api/qna/questions/status/accepted`,
                {
                    withCredentials: true,
                    params: queryParams,
                    validateStatus: status => status < 400,
                }
            );

            this.questions = response.data;

            return response;

        },

        async load({ lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/globals/search`,
                {
                    params: queryParams
                }
            );
            const { placeholder = null, buttonLabel = null, noResults = null, error = null } = response.data;

            this.labels.placeholder = placeholder;
            this.labels.buttonLabel = buttonLabel;
            this.labels.noResults = noResults;
            this.labels.error = error;

            return response;
        }
    }
});

const sortBy = (propertyPath, order = 'ASC') => {
    function deepGet(obj, path = "") {
        if (!path.length) { return obj; }

        let current = obj;

        for (const pathSegment of path.split('.')) {
            current = current[pathSegment];
        }

        return current;
    }

    const orders = {
        'ASC': (a, b) => deepGet(a, propertyPath) - deepGet(b, propertyPath),
        'DESC': (a, b) => deepGet(b, propertyPath) - deepGet(a, propertyPath)
    }

    return orders[order.toUpperCase()];
}

const deepReplace = (obj, path, replaceFn) => {
    const value = get(obj, path);
    set(obj, path, replaceFn(value));
    return obj;
}
