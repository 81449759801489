import { API_ENDPOINT, BACKEND_BASE } from '@/config';
import axios from 'axios';
import { defineStore } from 'pinia';

export const useQnaGlobalsStore = defineStore('qnaGlobals', {
    state: () => ({
        loaded: false,

        id: null,
        qnaHeader: null,

        qnaAllQuestionsTabLabel: null,
        qnaAllQuestionsSearchInputPlaceholder: null,
        qnaAllQuestionSearchSelectPlaceholder: null,

        qnaMyQuestionsTabLabel: null,
        qnaMyQuestionsUpdatesLabel: null,
        qnaMyQuestionsMyQuestionsLabel: null,

        qnaNewQuestionTabLabel: null,

        qnaNewQuestionTopicLabel: null,
        qnaNewQuestionTopicDescription: null,
        qnaNewQuestionTopicSelectPlaceholder: null,

        qnaNewQuestionSubjectLabel: null,
        qnaNewQuestionSubjectDescription: null,
        qnaNewQuestionSubjectPlaceholder: null,
        qnaNewQuestionSimilarQuestionsLabel: null,

        qnaNewQuestionBodyLabel: null,
        qnaNewQuestionBodyDescription: null,
        qnaNewQuestionBodyPlaceholder: null,

        qnaNewQuestionButtonSubmitLabel: null,

        qnaNewQuestionNoRecommondationsLabel: null,

        qnaQuestionVotesLabel: null,
        qnaQuestionAnswersLabel: null,

        qnaAnswerStatusMessageLabel: null,
        qnaAnswerAnswerLabel: null,

        qnaNewQuestionSubmitSuccess: null,
        qnaNewQuestionSubmitError: null,
        qnaMyQuestionsNoQuestionsHint: null,

        meta: null,

        topics: [],

    }),
    getters: {
        getTopics(state) {
            return state.topics
                .map(({ id, name, description }) => ({ id, label: name, value: id, description }))
        },
    },
    actions: {
        async load({ lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/globals/qna`,
                {
                    params: queryParams,
                    validateStatus: status => status < 400,
                }
            );

            Object.assign(this.$state, response.data);

            return response;
        },

    },
});
