import { API_BASE, isDev } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
        loaded: false,

        id: null,
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        canAccesCP: null,
        site: null,
        sites: null,
        visitor: true,

        preferences: {
            seenIntroductionTutorial: false,
            exploreMode: false,
        }
    }),

    getters: {
        getIsVisitor(state) {
            return !state.loaded || state.visitor
        },
        isLoggedIn(state) {
            return this.id !== null;
        },
        showIntroductionTutorial(state) {
            return !state.preferences?.seenIntroductionTutorial || false;
        }
    },
    actions: {
        async load({ queryParams = {} } = {}) {

            const response = await axios.get(
                `${API_BASE}/me`,
                {
                    params: queryParams,
                    withCredentials: true
                }
            );

            Object.assign(this.$state, response.data);

            return response;
        },

        async closeIntroduction({ queryParams = {} } = {}) {

            this.preferences.seenIntroductionTutorial = true;

            try {

                const response = await axios.post(
                    `${API_BASE}/preferences/write`,
                    {
                        seenIntroductionTutorial: true
                    },
                    {
                        params: queryParams,
                        withCredentials: true
                    }
                );

                return response;

            } catch (err) {

                isDev && console.log(err);

            }
        },
        async toggleExploreMode(value, { queryParams = {} } = {}) {

            this.preferences.exploreMode = !this.preferences.exploreMode;

            try {

                const response = await axios.post(
                    `${API_BASE}/preferences/write`,
                    {
                        exploreMode: this.preferences.exploreMode
                    },
                    {
                        params: queryParams,
                        withCredentials: true
                    }
                );

                return response;

            } catch (err) { }
        },
    }
});
