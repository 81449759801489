import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useLoginPageStore = defineStore("loginPage", {
    state: () => ({
        id: null,
        loginPage: null,
        meta: {},
        authState: null,
        posting: false,
    }),
    actions: {
        async load({ uri, lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/loginPages/${uri}`,
                {
                    params: queryParams,
                    validateStatus: (status) => status < 400
                }
            );

            const { meta = null, ...data } = response.data;

            this.meta = meta;
            this.loginPage = data;

            return response;

        },

        async post({ url, formFields }) {

            const form = new FormData();
            form.append('AuthState', this.authState);

            for (let k in formFields) {
                form.append(k, formFields[k]);
            }

            this.posting = true;
            try {
                const response = await axios.post(url, form);
                this.posting = false;
                return response;
            } catch(e) {
                this.posting = false;
                return false;
            }
        },
    },
});


