∑<script setup>
import { computed } from 'vue';
import { watchDebounced } from '@vueuse/core'

const props = defineProps({
    label: String,
    description: String,
    value: String,
    placeholder: String,
    required: Boolean,
    disabled: Boolean,
    error: Boolean,
});
const emit = defineEmits([ 'update:value' ]);
const updateValue = (event) => {
    emit('update:value', event.target.value);
}
</script>

<template>
    <div
        class="textarea"
        :class="{
            'textarea--disabled': disabled,
            'textarea--error': error
        }"
    >

        <label class="textarea__text">

            <span class="textarea__label">
                {{ label }}<span v-if="required" class="textarea__label--required">*</span>
            </span>

            <span v-if="description" class="textarea__description">{{ description }}</span>

            <textarea
                :placeholder="placeholder"
                :value="value"
                @input="updateValue"
                class="textarea__textarea textarea__field textarea__field--text" />
        </label>

    </div>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.textarea {
    display: flex;
    flex-flow: column;

    @include v((
        font-input-label: ( default: 700 16px/1.3 #{v(font-family-default-1)} ),
        font-input-placeholder: ( default: 400 14px/1.357 #{v(font-family-default-2)} ),
    ));

    & + .textarea {
        margin-top: v(size-26);
    }

    &__input {
        min-height: 150px;
    }

    &__text {
        grid-column: text-start / text-end;
        grid-row: text-start / text-end;
        display: flex;
        flex-flow: column;
        gap: v(size-2);

        // font: v(font-heading-4);
        font: v(font-input-label);
        color: v(color-primary);

        & + .textarea__field {
            margin-top: v(size-4);
        }
    }

    &__label--required {
        color: v(color-danger);
    }

    &__description {
        font: v(font-copy--small);
    }

    &__field {
        font: v(font-copy--small);

        &::placeholder {
            opacity: 1;
            font: v(font-copy--small);
            color: v(color-light);
        }

        &:focus {
            outline: 0;
            border-color: v(color-primary);
        }

        &--text {
            display: flex;
            background-color: v(color-white);
            border: 1px solid hsla(v(color-black-hsl), 0.2);
            color: v(color-dark);
            box-shadow: none;
            padding: v(size-16) v(size-16);
            resize: none;
            outline: none;
            scrollbar-width: thin;
            scrollbar-color: v(color-secondary) transparent;
            transition: border-color v(easing-default) v(duration-default);
        }

    }

    &--disabled {
        pointer-events: none;

        .textarea__label--required,
        .textarea__text {
            color: v(color-light);
        }

        .textarea__field {
            background-color: v(color-white);
            border-color: v(color-light);
            color: v(color-light);

            &::placeholder {
                color: v(color-light);
            }
        }

    }

    &--error {
        .textarea__field {
            border-color: v(color-danger);
            color: v(color-danger);

            &::placeholder {
                color: hsla(v(color-danger-hsl), .8);
            }
        }
    }
}

</style>
