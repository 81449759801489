import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";
import { useUserStore } from "@/store";

export const useHomeStore = defineStore("home", {
    state: () => ({
        id: null,
        title: null,
        meta: null,
        subline: null,
        headline: null,
        backgroundImage: null,
        contactInfo: null,
        content: [],
        introductionTutorial: [],
    }),
    getters: {
        getSubline(state) {
            const userStore = useUserStore();
            return this.subline?.replaceAll('{firstname}', userStore.firstName);
        }
    },
    actions: {
        async load({ lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/pages`,
                {
                    params: queryParams,
                    validateStatus: (status) => status < 400
                }
            );

            Object.assign(this.$state, response.data);

            return response;

        }
    }
});


