import { API_BASE, API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useAltaresCoursesStore = defineStore("altares-courses", {
    state: () => ({
        loaded: false,
        loading: false,
        success: null,
        error: null,
        data: []
    }),
    actions: {
        async load({ queryParams = {} }) {

            try {

                this.loading = true;

                const response = await axios.get(
                    `/api/altares-courses-api`,
                    {
                        params: queryParams
                    }
                );

                Object.assign(this.$state, response.data);

                return response;
            } catch(error) {
                console.log(error);
            } finally {
                this.loading = false;
            }

        }
    }
});


