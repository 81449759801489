<script setup>
import { useQnaGlobalsStore, useQnaMyQuestionsStore, useQnaTopicsStore } from '@/store';
import { BaseSection } from '@bam/base-section';
import { Button } from '@bam/button';
import { Modal } from '@bam/modal';
import { RichText } from '@bam/rich-text';
import { ExpandTransition, FadeTransition } from '@bam/transitions';
import { refAutoReset } from '@vueuse/shared';
import { computed, ref } from 'vue';

const props = defineProps({
    open: Boolean,
    contentHeightFixed: Boolean,
});

const emit = defineEmits(['update:open']);
const open = computed({
    get() { return props.open },
    set(value) { emit('update:open', value) }
});

const activeTab = ref('newQuestion');
const setTab = (value) => { activeTab.value = value };
const isTab = (value) => activeTab.value === value;

const qnaGlobalsStore = useQnaGlobalsStore();
const qnaTopicsStore = useQnaTopicsStore();
const qnaMyQuestionsStore = useQnaMyQuestionsStore();

qnaGlobalsStore.load({ lang: 'de' });
qnaTopicsStore.loadTopics({ lang: 'de' });

const submitMessage = refAutoReset(null, 10000);

function onSubmitQuestion({ success }) {
    if (success) {
        setTab('myQuestions')
        submitMessage.value = qnaGlobalsStore.qnaNewQuestionSubmitSuccess;
    } else {
        submitMessage.value = qnaGlobalsStore.qnaNewQuestionSubmitError;
    }
}
</script>

<template>
    <Modal
        :content-height-fixed="contentHeightFixed"
        v-model:open="open"
        class="qna-chat-modal"
    >
        <BaseSection
            section-background="#FFF"
            section-foreground="dark"
            class="qna-chat-modal__section"
        >

            <RichText :text="qnaGlobalsStore.qnaHeader" />

            <div class="qna-chat-modal__buttons">
                <Button :variant="isTab('newQuestion') ? 'solid' : 'outlined'" @click="setTab('newQuestion')">
                    {{qnaGlobalsStore.qnaNewQuestionTabLabel}}
                </Button>
                <Button :variant="isTab('myQuestions') ? 'solid' : 'outlined'" @click="setTab('myQuestions')">
                    {{qnaGlobalsStore.qnaMyQuestionsTabLabel}}
                    <span v-if="qnaMyQuestionsStore.hasAnyUnreadChanges" class="qna-chat-modal__button-badge"></span>
                </Button>

                <!-- <Button :variant="isTab('allQuestions') ? 'solid' : 'outlined'" @click="setTab('allQuestions')">
                    {{qnaGlobalsStore.qnaAllQuestionsTabLabel}}
                </Button> -->
            </div>

            <ExpandTransition>
                <RichText
                    v-if="submitMessage"
                    :text="submitMessage"
                />
            </ExpandTransition>

            <FadeTransition mode="out-in">

                <NewQuestion
                    v-if="isTab('newQuestion')"
                    @submit-question="onSubmitQuestion"
                    key="newQuestion"
                />

                <MyQuestions
                    v-else-if="isTab('myQuestions')"
                    key="myQuestions"
                />

                <!-- <AllQuestions
                    v-else-if="isTab('allQuestions')"
                    key="allQuestions"
                /> -->

            </FadeTransition>

        </BaseSection>

    </Modal>

</template>

<style lang="scss">
@use "@bam/scss" as *;
.qna-chat-modal {

    .rich-text {
        margin-bottom: v(size-26);
    }

    &__section {
        row-gap: v(size-10);
        align-content: start;

        > * {
            grid-column: grid-start / grid-end;

            @include mq-from(md) {
                grid-column: column-2-start / column-11-end;
            }

            @include mq-from(lg) {
                grid-column: column-3-start / column-10-end;
            }

            @include mq-from(xl) {
                grid-column: column-4-start / column-9-end;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-flow: row wrap;
        gap: v(size-10);
        margin-bottom: 35px;
    }

    &__button-badge {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: v(color-secondary);
        box-shadow: inset 1px 2px 3px rgba(255, 255, 255, 0.35);
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }

}
</style>
