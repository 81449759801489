<script setup>
import { RouterLink, useRouter } from 'vue-router';
import { computed } from 'vue';
import { hasProtocol } from 'ufo';

const props = defineProps({
    to: { type: [String, Object] },
    href: { type: [String, Object] },
    target: { type: String },
    rel: { type: String, default: 'noopener noreferrer' },
    noRel: { type: Boolean },
    activeClass: { type: String },
    exactActiveClass: { type: String },
    replace: { type: Boolean },
    ariaCurrentValue: { type: String },
    external: { type: Boolean },
    custom: { type: Boolean },
})

const router = useRouter();

const to = computed(() => {
    return props.to || props.href || ''
})

const isExternal = computed(() => {

    if (props.external) {
        return true
    }

    if (props.target && props.target !== '_self') {
        return true
    }

    if (typeof to.value === 'object') {
        return false
    }

    return to.value === '' || hasProtocol(to.value, true)
})

// Internal link
const routerLinkProps = computed(() => ({
    to: to.value,
    activeClass: props.activeClass,
    exactActiveClass: props.activeClass,
    replace: props.replace,
    ariaCurrentValue: props.ariaCurrentValue
}));

// External link
const href = typeof to.value === 'object'
    ? router.resolve(to.value)?.href ?? null
    : to.value || null

const target = props.target || null

const rel = props.noRel
    ? null
    : (props.rel ?? (href ? "noopener noreferrer" : '')) || null

const externalLinkProps = computed(() => ({
    href,
    rel,
    target
}))
</script>

<template>
    <RouterLink class="app-link" v-if="!isExternal" v-bind="routerLinkProps">
        <slot />
    </RouterLink>

    <a class="app-link" v-else v-bind="externalLinkProps">
        <slot />
    </a>
</template>

<style lang="scss">
@use "@bam/scss" as *;

a.app-link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
</style>
