import { computed } from "vue";
import { useRoute } from "vue-router";
export const useCanGoBack = () => {

    const route = useRoute();

    const canGoBack = computed(() => {
        return route.name !== 'home';
    });

    return {
        canGoBack
    }
}
