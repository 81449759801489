<script setup>
import { useDebounceFn } from '@vueuse/core';
import { useQnaNewQuestionStore, useQnaGlobalsStore } from '@/store';
import { ExpandTransition } from '@bam/transitions';
import { Icon } from '@bam/icon';
import { Button } from '@bam/button';

const qnaGlobalsStore = useQnaGlobalsStore();
const qnaStore = useQnaNewQuestionStore();

qnaStore.loadTopics();

const emit = defineEmits(['submitQuestion']);

function submit() {
    qnaStore
        .postQuestion()
        .then(() => { emit('submitQuestion', { success: true }) })
        .catch(() => { emit('submitQuestion', { success: false }) })
}

const loadSuggestionsDebounced = useDebounceFn(() => {
    qnaStore.loadSuggestions()
}, 300, { maxWait: 2000 });
</script>

<template>

    <form class="qna-new-question" @submit.prevent="submit">

         <SelectInput
            :label="qnaGlobalsStore.qnaNewQuestionTopicLabel"
            :description="qnaGlobalsStore.qnaNewQuestionTopicDescription"
            :placeholder="qnaGlobalsStore.qnaNewQuestionTopicSelectPlaceholder"
            :options="qnaStore.getTopics"
            required
            v-model:selected="qnaStore.topic"
        />

        <TextInput
            :label="qnaGlobalsStore.qnaNewQuestionSubjectLabel"
            :description="qnaGlobalsStore.qnaNewQuestionSubjectDescription"
            :placeholder="qnaGlobalsStore.qnaNewQuestionSubjectPlaceholder"

            required
            v-model:value="qnaStore.subject"
            @input="loadSuggestionsDebounced"
            :loading="qnaStore.loadingSuggestions"
        />

        <ExpandTransition>
            <div class="qna-new-question__suggestions" v-if="qnaStore.getTop5Suggestions?.length > 0">
                <span class="qna-new-question__suggestions-headline">{{ qnaGlobalsStore.qnaNewQuestionSimilarQuestionsLabel }}</span>
                <span class="qna-new-question__suggestions-close" @click="qnaStore.resetSuggestions">
                    <Icon name="close" />
                </span>
                <div class="qna-new-question__suggestions__items">
                    <Question
                        v-for="(question, index) in qnaStore.getTop5Suggestions"
                        :key="question.id"

                        :subject="question.headline"
                        :body="question.question"

                        :show-status-message="false"

                        :labels="{
                            question: {
                                votes: qnaGlobalsStore.qnaQuestionVotesLabel,
                                answers: qnaGlobalsStore.qnaQuestionAnswersLabel,
                            },
                            answer: {
                                statusMessage: qnaGlobalsStore.qnaAnswerStatusMessageLabel,
                                answer: qnaGlobalsStore.qnaAnswerAnswerLabel,
                            }
                        }"

                        :votes="question.votes"
                        :answers="question.answers"
                        :isVerified="question.isVerified"
                        :status="question.status"
                        :status-message="question.statusMessage"
                        :topic="question.topic"
                        :author="question.author"
                        :dateCreated="question.dateCreated"
                        :dateUpdated="question.dateUpdated"
                    />
                </div>
            </div>
        </ExpandTransition>

        <Textarea
            :label="qnaGlobalsStore.qnaNewQuestionBodyLabel"
            :description="qnaGlobalsStore.qnaNewQuestionBodyDescription"
            :placeholder="qnaGlobalsStore.qnaNewQuestionBodyPlaceholder"
            v-model:value="qnaStore.body"
        />

        <Button type="submit">
            {{ qnaGlobalsStore.qnaNewQuestionButtonSubmitLabel }}
        </Button>

    </form>

</template>

<style lang="scss">
@use "@bam/scss" as *;

.qna-new-question {
    display: flex;
    flex-flow: column nowrap;
    gap: v(size-26);


    &__suggestions {

        @include v((
            panel-gap: v(size-4)
        ));

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;

        &-headline {
            font: v(font-heading-4);
            color: v(color-primary);
        }

        &-close {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: v(color-primary);
        }

        &__items {
            width: 100%;
            border: 1px solid v(color-light);
            max-height: 200px;
            overflow: auto;
        }

        .accordion-panel__header {
            font: initial;
            padding: v(size-10);
        }

        .question-header {
            padding: v(size-16) v(size-16) v(size-26) 46px;

            &__subject {
                font: v(font-heading-4);
            }
        }
    }
}
</style>
