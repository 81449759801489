import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useNavigationStore = defineStore("navigation", {
    state: () => ({
        loaded: false,
        header: [],
        footer: [],
    }),
    getters: {
        headerLinks(state) {
            return this.header.map(item => item);
        },
        footerLinks(state) {
            return this.footer.map(item => item);
        }
    },
    actions: {
        async load({ lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/navigation/footer`,
                {
                    params: queryParams,
                    validateStatus: (status) => status < 400
                }
            );

            const { data = null, meta = null } = response.data;
            this.footer = data;

            return response;
        }
    }
});


