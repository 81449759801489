<script setup>
import { Icon } from '@bam/icon';
import { computed } from 'vue';
import { ExpandTransition } from '@bam/transitions';

const props = defineProps({
    label: String,
    description: String,
    placeholder: String,
    options: Array,
    selected: {
        type: [String, Number],
        default: ""
    },
    inverted: Boolean,
    required: Boolean,
    disabled: Boolean,
    error: Boolean,
})
const emit = defineEmits(['update:selected']);

const selected = computed({
    get() { return props.selected; },
    set(value) { emit('update:selected', value) }
})

const selectedOption = computed(() => {
    return props.options.find(({ value }) => value === props.selected);
})
</script>

<template>
    <div
        class="select-input"
        :class="{
            'select-input--disabled': disabled,
            'select-input--error': error,
            'select-input--inverted': inverted,
            'select-input--selected': selected,
        }"
    >

        <label class="select-input__text" v-if="label || description">

            <span class="select-input__label">
                {{ label }}<span v-if="required" class="select-input__label--required">*</span>
            </span>

            <div
                v-if="description"
                v-html="description"
                class="select-input__description"
            />

        </label>

        <select
            class="select-input__select select-input__field select-input__field--select"
            v-model="selected"
            :required="required"
        >
            <option v-if="placeholder" class="select__placeholder" disabled selected hidden value>{{ placeholder }}</option>

            <option
                :key="index"
                :value="value"
                :selected="selectedItem"
                v-for="({ value, label, selected: selectedItem }, index) in options"
                class="select__value"
            >{{ label }}</option>
        </select>

        <Icon
            name="arrow-down"
            class="select-input__icon"
        />

        <ExpandTransition>
            <div
                v-if="selectedOption?.hint"
                v-text="selectedOption?.hint"
                class="select-input__hint"
            />
        </ExpandTransition>

    </div>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.select-input {
    display: grid;

    grid-template-columns: [text-start select-start icon-start] auto [text-end select-end icon-end];
    grid-template-rows: [text-start] auto [text-end select-start icon-start] auto [select-end icon-end];
    min-width: 80px;

    @include v((
        font-input-label: ( default: 400 16px/1.3 #{v(font-family-default-1)} ),
        font-select: ( default: normal 14px/1.357 #{v(font-family-default-2)} )
    ));

   &__text {
        grid-column: text-start / text-end;
        grid-row: text-start / text-end;
        display: flex;
        flex-flow: column;
        gap: v(size-2);

        font: v(font-input-label);
        color: v(color-primary);

        & + .select-input__field {
            margin-top: v(size-4);
        }
    }

    &__label--required {
        color: v(color-danger);
    }

    &__description {
        font: v(font-copy--small);
    }

    &__hint {
        font: v(font-copy--small);
        color: v(color-warning);
        margin-top: v(size-4);
    }

    &__select {
        grid-column: select-start / select-end;
        grid-row: select-start / select-end;

        background-color: v(color-white);
        border: 1px solid hsla(v(color-black-hsl), 0.2);
        color: v(color-light);
        padding: v(size-10) v(size-16);
        overflow: hidden;
        text-overflow: ellipsis;

        appearance: none;
        width: 100%;
        padding-right: 29px;
        font: v(font-select);

        option {
            font: v(font-select);
            background-color: initial;
        }

        &:focus {
            color: v(color-dark);

            option {
                font-family: Arial, sans-serif;
            }
        }
    }

    &__icon {
        @include svg-auto-color;
        grid-column: icon-start / icon-end;
        grid-row: icon-start / icon-end;

        width: 20px;
        height: 20px;
        align-self: center;
        color: v(color-primary);

        z-index: 1;
        justify-self: end;

        margin: 0 v(size-10);
        pointer-events: none;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--selected {
        .select-input__select {
            color: v(color-dark);
        }
    }

    &--inverted {

        .select-input__select {
            border-color: v(color-primary);
            background-color: v(color-primary);
            color: v(color-text-contrast);
        }

        .select-input__icon {
            color: v(color-white);
        }
    }

    &.select-input--disabled {

        .select-input__select {
            border-color: v(color-light);
            background-color: v(color-white);
            color: v(color-light);
        }

        .select-input__icon {
            color: v(color-light);
        }
    }

    &.select-input--error {

        .select-input__select {
            border-color: v(color-danger);
            background-color: v(color-white);
            color: v(color-danger);
        }

        .select-input__icon {
            color: v(color-danger);
        }
    }

}
</style>
