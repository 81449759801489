import { API_TRACK, isDev } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useTrackingStore = defineStore("tracking", {
    state: () => ({
        entryId: null,
        siteId: null,
    }),
    actions: {
        async trackPageVisit() {
            if (this.isPreview) { return };

            return axios
                .post(API_TRACK, {
                    type: "bam\\crafttrack\\trackers\\base\\ViewCountTracker",
                    elementId: this.$state.entryId,
                })
                .then((response) => { isDev && console.log("trackPageVisit[✅]", response.data); })
                .catch(err => { isDev && console.log("trackPageVisit[💥]", err) })

        },

        async trackKnowledgeTestCompleted({ passed }) {
            if (this.isPreview) { return };

            return axios
                .post(API_TRACK, {
                    type: "bam\\crafttrack\\trackers\\SimpleKtTracker",
                    elementId: this.$state.entryId,
                    params: {
                        passed: passed,
                    }
                })
                .then((response) => { isDev && console.log("trackKnowledgeTestCompleted[✅]", response.data); })
                .catch(err => { isDev && console.log("trackKnowledgeTestCompleted[💥]", err) })

        },
        async trackVideo({ id : assetId, progress }) {
            if (this.isPreview) { return };

            return axios
                .post(API_TRACK, {
                    type: "bam\\crafttrack\\trackers\\VideoTracker",
                    elementId: assetId,
                    params: {
                        progress: progress,
                    }
                })
                .then((response) => { isDev && console.log("trackVideo[✅]", response.data); })
                .catch(err => { isDev && console.log("trackVideo[💥]", err) })

        },

        async trackRequirementPath({ entryId }) {
            if (this.isPreview) { return };

            return axios
                .post(API_TRACK, {
                    type: "bam\\requirementpath\trackers\\RequirementPathTracker",
                    elementId: assetId,
                    params: {
                        entryId: entryId,
                    }
                })
                .then((response) => { isDev && console.log("trackVideo[✅]", response.data); })
                .catch(err => { isDev && console.log("trackVideo[💥]", err) })

        },

        async trackCompletion() {
            if (this.isPreview) { return };

            return axios
                .post(API_TRACK, {
                    type: "bam\\crafttrack\\trackers\\base\\CompletionTracker",
                    elementId: this.$state.entryId,
                })
                .then((response) => { isDev && console.log("trackCompletion[✅]", response.data); })
                .catch(err => { isDev && console.log("trackCompletion[💥]", err) })

        },

    }
});
