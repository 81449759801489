import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";
import { paramCase as kebabCase } from 'change-case';
import { shuffle } from 'lodash';

export const useKnowledgeTestStore = defineStore("knowledgeTest", {
    state: () => ({
        id: null,
        title: null,
        meta: {},
        uri: null,
        slug: null,
        completionPercentage: null,
        maximumInteractions: null,
        retryLimit: null,
        randomizeOptions: null,
        randomizeInteractions: false,
        questionEntries: [],
        startScreen: null,
        labels: {},
        feedbacks: [],

        activeQuestion: 0,

        evaluations: [],
    }),
    getters: {
        transformQuestionEntry(state) {
            return (question) => ({
                ...question,
                id: question.id,
                type: kebabCase(question.type),
                richText: { text: question.interactionText },
                choiceProps: question.choiceProps,
                choices: question.choiceOptions?.map((choice, index) => ({ ...choice, id: index })),
                sortable: question?.sortableOptions,
                randomizeOptions: state?.randomizeOptions,
                valueSlider: question?.valueSliderOptions
            });
        },

        shuffleQuestionEntries(state) {
            return questionEntries => {
                return state.randomizeInteractions
                    ? shuffle(questionEntries)
                    : questionEntries;
            };
        },

        limitQuestionEntries(state) {
            return questionEntries => {
                // @ts-ignore
                if (isNaN(state.maximumInteractions) || questionEntries < 0) {
                    return questionEntries;
                }

                return questionEntries?.slice(null, state.maximumInteractions);
            }
        },

        getQuestionEntries(state) {
            let questionEntries = this.questionEntries.map(this.transformQuestionEntry);

            questionEntries = this.shuffleQuestionEntries(questionEntries);
            questionEntries = this.limitQuestionEntries(questionEntries);

            return questionEntries;
        },

        getQuestionCount(state) {
            return this.getQuestionEntries.length;
        },
        getCurrentQuestion(state) {
            return this.getQuestionEntries.find((question, index) => index === this.activeQuestion);
        },
        getKnowledgeTestPercentage(state) {
            const passedCount = state.evaluations
                ?.filter(({ passed }) => passed)
                .length

            return passedCount / this.getQuestionCount;
        },

        getKnowledgeTestPassed(state) {
            return this.getKnowledgeTestPercentage >= state.completionPercentage;
        },

        getCurrentFeedback(state) {
            const feedbackType = this.getKnowledgeTestPassed
                ? 'positive'
                : 'negative';

            return state.feedbacks.find(({ type }) => type === feedbackType);
        },
    },
    actions: {
        async load({ slug, lang, queryParams = {} }) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/knowledgeTests/slug/${slug}`,
                {
                    params: queryParams,
                    validateStatus: (status) => status < 400
                }
            );

            Object.assign(this.$state, response.data);

            return response;

        },

        async addEvaluation(evaluation) {
            this.$state.evaluations.push(evaluation);
        },

        async reset() {
            this.evaluations = [];
        }
    }
});

