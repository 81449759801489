<script setup>
import { Sidebar } from '@bam/sidebar';
import { useUserStore, useNavigationStore } from "@/store";
import { Icon } from '@bam/icon';
import SidebarLink from './SidebarLink.vue';

const userStore = useUserStore();
const navigationStore = useNavigationStore();

function openTutorial() {
    userStore.preferences.seenIntroductionTutorial = false;
}
</script>

<template>
    <Sidebar class="user-menu-sidebar">
        <template #default="{ closeSidebar }">
            <span class="user-menu-sidebar__welcome">
                {{ $g('hey-user', { '{firstname}': userStore.firstName }) }}
            </span>

            <template v-if="userStore.sites?.length > 1">
                <hr class="user-menu-sidebar__separator" />

                <ul class="user-menu-sidebar__actions">
                    <li class="user-menu-sidebar__action">
                        <Icon class="user-menu-sidebar__icon" name="contenttype-softcontent" />
                        <span class="user-menu-sidebar__label">Sprache</span>
                        <!-- {{ $g('language') }} -->
                        <ul class="user-menu-sidebar__languages">
                            <li
                                v-for="{ id, language } in userStore.sites"
                                class="user-menu-sidebar__language-button"
                                :class="{
                                    'user-menu-sidebar__language-button--active': userStore.site?.id == id
                                }"><a href="#">{{ language.toUpperCase() }}</a></li>
                        </ul>
                    </li>
                </ul>
            </template>

            <hr class="user-menu-sidebar__separator" />

            <ul class="user-menu-sidebar__actions">
                <li v-if="userStore.canAccesCP" class="user-menu-sidebar__action">
                    <SidebarLink
                        icon="show"
                        :label="$g('cms-introduction-tutorial')"
                        @click="() => { closeSidebar(); openTutorial(); }"
                    />
                </li>
                <li v-if="userStore.canAccesCP" class="user-menu-sidebar__action">
                    <SidebarLink
                        icon="setting"
                        href="/admin" rel="noopener" target="_blank"
                        :label="$g('cms-backend')"
                        @click="closeSidebar"
                    />
                </li>
                <!-- <li class="user-menu-sidebar__action">
                    <SidebarLink
                        icon="setting"
                        :label="$g('cms-dashboard')"
                        :to="{ name: 'dashboard' }"
                        @click="closeSidebar"
                    />
                </li> -->
                <li class="user-menu-sidebar__action">
                    <SidebarLink
                        icon="logout"
                        :label="$g('logout')"
                        href="/sso/logout/request"
                        :external="true"
                    />
                </li>
            </ul>

            <hr class="user-menu-sidebar__separator" />

            <ul class="user-menu-sidebar__actions">
                <li
                    class="user-menu-sidebar__action"
                    v-for="linkItem in navigationStore.footer"
                    :key="linkItem.id"
                >
                    <SidebarLink
                        v-if="linkItem.link?.uri"
                        @click="closeSidebar"
                        :to="{ path: linkItem.link.uri }"
                        :label="linkItem.link.title"
                    />
                </li>
            </ul>
        </template>
    </Sidebar>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.user-menu-sidebar {

    &__welcome {
        font: v(font-heading-3);
        color: v(color-primary);
    }

    &__separator {
        width: 100%;
        height: 0px;
        border: 0;
        border-top: 1px solid v(color-light);
        margin-top: v(size-26);
        margin-bottom: v(size-26);
    }

    &__actions {
        list-style: none;
        margin: 0;
        padding: 0;

        display: flex;
        flex-flow: column nowrap;
        gap: v(size-20);
        color: v(color-dark);
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: v(size-6);
        flex: 1;
    }

    &__icon {
        flex: 0 0 20px;
    }

    &__label {
        font: v(font-copy);
        font-weight: bold;
        color: currentColor;
        text-decoration: none;
    }

    &__link {
        transition: color v(easing-default) v(duration-default);

        @include hover {
            color: v(color-secondary);
        }
    }

    &__languages {
        display: flex;
        flex-flow: row nowrap;
        gap: v(size-6);
        list-style: none;
        padding: 0;
        margin: 0;
        margin-left: v(size-10);
        overflow: auto;
    }

    &__language-button {

        display: flex;
        align-items: center;
        justify-content: center;
        color: v(color-light);
        font-family: v(font-family-default-2);
        font-size: 14px;
        line-height: 14px;
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        border-radius: 50%;
        border: 1px solid currentColor;
        transition: color v(easing-default) v(duration-default);
        cursor: pointer;

        @include hover {
            color: v(color-secondary);
        }

        &--active {
            color: v(color-secondary);
        }

        a {
            text-decoration: none;
            color: currentColor;
        }
    }
}
</style>
