import { defineComponent, h } from "vue";

export const useSvgThemeLoader = {
    install: (app, options) => {


        const svgs = import.meta.globEager("../../node_modules/@bam/theme-hyundai/src/icons/**/*.svg", { as: 'raw' });

        const svgComponents = {};
        Object
            .entries(svgs)
            .forEach(([ path, svgString ]) => {
                const segments = path
                    .replace(/^.+\//, '') // get filename
                    .replace(/\.\w+$/, '') // remove file extension
                    .split(/[/_-]/)
                    .map(segment => segment.toLowerCase()) // normalize to lower
                    ;

                const camelCasedSegments = segments.map(segment => segment.charAt(0).toUpperCase() + segment.slice(1));

                const componentName = `BamIcon${camelCasedSegments.join('')}`;
                const className = segments.join('-');

                svgComponents[componentName] = {
                    path,
                    componentName,
                    className,
                    segments,
                    camelCasedSegments,
                    svgString,
                };
            });

        // register the (merged) icon set
        for (const [name, { className, svgString }] of Object.entries(svgComponents)) {
            const component = defineComponent({
                name: name,
                setup(props) {
                    return () => h('i', { class: `icon icon-${className}`, innerHTML: svgString });
                }
            })
            app.component(name, component);
        }

    },
};
