// plugins/globalTexts.js
import { useUiTextsStore } from "@/store";

export const useUITexts = {
    install: (app, options) => {
        const uiTextsStore = useUiTextsStore();

        function getMessage(key, replacementMap = {}) {
            let message = uiTextsStore.uiTexts[key];

            if (!message && process.env.NODE_ENV !== 'production') {
                return `⚠️ Missing Key: ${key}`;
            }

            if (!message) {
                return '';
            }

            for (const key in replacementMap) {
                message = message.replaceAll(key, replacementMap[key]);
            }
            return message;
        }

        function setMetaTitle(title) {
            const baseTitle = getMessage('document-title-base');

            if (!title) {
                document.title = baseTitle;
                return;
            }

            document.title = `${title} | ${baseTitle}`;
        }

        app.config.globalProperties.$g = getMessage;
        app.config.globalProperties.$setMetaTitle = setMetaTitle;

        app.mixin({
            methods: {
                $g: getMessage,
                $setMetaTitle: setMetaTitle
            },
        });
    }
}
