import { API_BASE, API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const useDashboardStore = defineStore("dashboard", {
    state: () => ({
        loaded: false,
        dashboardLabel: null,
        dashboardTotalProgressHeadline: null,
        dashboardCompleteLabel: null,
        dashboardIncompleteLabel: null,
        dashboardCardsDoneLabel: null,
        dashboardText: null,
        totalProgress: 0,

        data: [
            {
                progress: 60,
                label: "completedLabel",
                category: "Fahrzeuge",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "KONA N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONICQ 5",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "TUCSON",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONIQ Marke",
                        state: "default",
                        overlayHeading: "0/5",
                    }
                ]
            },
            {
                progress: 30,
                label: "completedLabel",
                category: "Brand",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    }
                ]
            },
            {
                progress: 100,
                label: "completedLabel",
                category: "E-Mobilität",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "KONA N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONICQ 5",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "TUCSON",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONIQ Marke",
                        state: "default",
                        overlayHeading: "0/5",
                    }
                ]
            },
            {
                progress: 75,
                label: "completedLabel",
                category: "Brand",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "KONA N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONICQ 5",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "TUCSON",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONIQ Marke",
                        state: "default",
                        overlayHeading: "0/5",
                    }
                ]
            },
            {
                progress: 60,
                label: "completedLabel",
                category: "Fahrzeuge",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "KONA N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONICQ 5",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "TUCSON",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONIQ Marke",
                        state: "default",
                        overlayHeading: "0/5",
                    }
                ]
            },
            {
                progress: 30,
                label: "completedLabel",
                category: "Brand",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "KONA N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONICQ 5",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "TUCSON",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONIQ Marke",
                        state: "default",
                        overlayHeading: "0/5",
                    }
                ]
            },
            {
                progress: 100,
                label: "completedLabel",
                category: "E-Mobilität",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "KONA N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONICQ 5",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "TUCSON",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONIQ Marke",
                        state: "default",
                        overlayHeading: "0/5",
                    }
                ]
            },
            {
                progress: 75,
                label: "completedLabel",
                category: "Brand",
                cards: [
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "BAYON",
                        state: "in-progress",
                        overlayHeading: "4/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "i30 N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "KONA N",
                        state: "completed",
                        overlayHeading: "5/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONICQ 5",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "TUCSON",
                        state: "default",
                        overlayHeading: "0/5",
                    },
                    {
                        image: {
                            src: 'https://picsum.photos/id/598/300/150',
                            alt: 'Image alternative text',
                        },
                        title: "IONIQ Marke",
                        state: "default",
                        overlayHeading: "0/5",
                    }
                ]
            }
        ],
        data: [],

        meta: {},
    }),

    getters: {
        getData(state) {
            return state.data.map(reqPath => ({
                progress: 50,
                label: state.dashboardCompleteLabel,
                category: reqPath.name,
                cards: reqPath.nodes.map(node => ({
                    ...node,
                    overlayHeading: 'x/y',
                    image: {
                        src: 'http://vtc.hyundai.nitro/assets-secure/images/2020_i20/2020_i20_Kacheln/i20_Upselling_tile.jpg'
                    }
                })),
            }))
        }
    },

    actions: {
        async load({ lang } = {}) {
            this.loaded = false;

            const response = await axios.get(
                [API_ENDPOINT(lang), 'dashboard'].join('/'),
                {
                    validateStatus: (status) => status < 400
                }
            );

            Object.assign(this.$state, response.data);

            this.loaded = true;

            return response;
        }
    }
});

