<script setup>
import { AccordionPanel } from '@bam/accordion';
import { computed, ref } from 'vue';
import QuestionHeader from './QuestionHeader.vue';

const emit = defineEmits([ 'question-opened' ]);

const props = defineProps({
    id: [String, Number],
    subject: String,
    body: String,
    votes: Number,
    answers: {Array},
    isVerified: Boolean,
    status: Object,
    statusMessage: String,
    topic: Object,
    author: Object,
    dateCreated: [String, Date],
    dateUpdated: [String, Date],
    lastRelevantChange: [String, Date],
    labels: Object,
    locale: String,
})

const open = ref(false);
const answersCount = computed(() => props.answers.length);
const firstAnswer = computed(() => {
    return props.answers?.find(Boolean)
});

function panelChanged({ open }) {
    if (open) {
        emit('question-opened');
    }
}
</script>

<template>
    <AccordionPanel
        :open="open"
        icon="plus"
        icon-position="left"
        class="question"
        @changed="panelChanged"
    >

        <template #header>
            <span class="question-header__subject">
                {{ subject }}
            </span>
        </template>

        <QuestionHeader
            class="question__header"
            :subject="subject"
            :body="body"
            :status="status"
            :topic="topic.name"
            :date-created="dateCreated"
            :date-updated="dateUpdated"
            :author="author?.username"
            :locale="locale"
        />

        <div v-if="statusMessage" class="question__status-message">

            <Answer
                :label="labels.answer.statusMessage"
                :answer="statusMessage"
                :author="author"
                :date-updated="dateUpdated"
            />

        </div>

        <div class="question__answers">

            <Answer
                v-if="firstAnswer"
                :label="labels.answer.answer"
                v-bind="firstAnswer"
                :is-verified="firstAnswer.verified"
            />

        </div>

    </AccordionPanel>
</template>

<style lang="scss">
@use "@bam/scss" as *;

.question {

    &.accordion-panel {

        &.accordion-panel--active {
            background-color: hsla(v(color-primary-hsl), 0.1);
        }

        & .accordion-panel__content {
            grid-template-columns: [icon-start] 0px [icon-end content-start] 1fr [content-end];
            padding: 0;
        }
    }

    &__subject {
        font: v(font-heading-2);
    }

    &__body {
        font: v(font-copy);
    }

    &__topics {
        align-self: start;
    }

    &__author {
        align-self: end;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .topic {
        display: inline-block;
        background-color: v(color-secondary);
        color: v(color-white);
        padding: v(size-4);
        font: v(font-copy--small);
        line-height: 1;
    }

    &__status-message {
        display: flex;
        margin-bottom: v(size-2);
    }

    &__answers {
        display: flex;
        flex-flow: column nowrap;
        gap: v(size-10);
    }

}
</style>
