import { API_ENDPOINT } from "@/config";
import axios from "axios";
import { defineStore } from "pinia";

export const usePageStore = defineStore("page", {
    state: () => ({
        id: null,
        title: null,
        meta: {},
        content: []
    }),
    actions: {
        async load({ uri, lang, queryParams = {}}) {

            const response = await axios.get(
                `${API_ENDPOINT(lang)}/pages/${uri}`,
                {
                    params: queryParams,
                    validateStatus: (status) => status < 400
                }
            );

            Object.assign(this.$state, response.data);

            return response;

        }
    }
});


