import { API_ENDPOINT, BACKEND_BASE } from '@/config';
import axios from 'axios';
import { defineStore } from 'pinia';
import { useQnaTopicsStore } from '.';

export const useQnaNewQuestionStore = defineStore('qnaNewQuestion', {
    state: () => ({
        loaded: false,

        loadingSuggestions: false,
        suggestions: [],
        topics: [],

        topic: '',
        subject: '',
        body: '',

        questionPost: null,
    }),

    getters: {
        getTop5Suggestions(state) {
            return state.suggestions.slice(0,5);
        },

        getInput(state) {
            return state.subject.toLowerCase();
        },
        getTopics(state) {
            const qnaTopicsStore = useQnaTopicsStore();
            return qnaTopicsStore.getTopics;
        },
    },
    actions: {
        async loadSuggestions({ lang = null, queryParams = {} } = {}) {

            this.loadingSuggestions = true;
            try {
                const response = await axios.post(
                    `${BACKEND_BASE}/api/qna/questions/suggestions`,
                    {
                        subject: this.subject,
                    },
                    {
                        withCredentials: true,
                        params: queryParams,
                        validateStatus: status => status < 400,
                    }
                );
                this.loadingSuggestions = false;
                this.suggestions = response.data;
            } catch(e) {
                this.loadingSuggestions = false;
            }
        },

        async loadTopics({ lang = null, queryParams = {} } = {}) {

            const response = await axios.get(
                `${BACKEND_BASE}/api/qna/topics`,
                {
                    withCredentials: true,
                    params: queryParams,
                    validateStatus: status => status < 400,
                }
            );

            this.topics = response.data;
        },

        async postQuestion({ lang = null, queryParams = {} } = {}) {

            const response = await axios.post(
                `${BACKEND_BASE}/api/qna/questions/new`,
                {
                    topic: this.topic,
                    subject: this.subject,
                    body: this.body,
                },
                {
                    withCredentials: true,
                    params: queryParams,
                    validateStatus: status => status < 400,
                }
            );

            this.$patch({
                topic: '',
                subject: '',
                body: '',
                suggestions: [],
            });

            this.questionPost = response.data;

        },

        resetSuggestions() {
            this.suggestions = [];
        }
    },
});
